import './component/polyfills';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/svg-arrow.css';
import slider from './component/Slider';
import breadcrumb from './component/breadcrumb';
import Sidebar from './component/Sidebar';

import './component/Box';
import './component/SwitchText';
import './component/BoxUtility';

document.addEventListener('DOMContentLoaded', () => {
  tippy('[data-tippy-content]', {
    theme: 'gl',
  });
  slider();
  breadcrumb();

  // Sidebars

  // Receipt checkout
  const receiptSidebar = document.querySelector('.gl_cart_summary_sidebar');
  const toggleButtonReceiptSidebar = document.querySelector('.gl_cart_summaryButtonToggle');
  if (receiptSidebar && toggleButtonReceiptSidebar) {
    const sidebar = new Sidebar(
      receiptSidebar,
      toggleButtonReceiptSidebar,
    );
    sidebar.init();
  }
});
