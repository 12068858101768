export default () => {
  const r = document.querySelector('[type=range]');

  document.documentElement.classList.add('js');

  if (r) {
    r.addEventListener('input', () => {
      r.style.setProperty('--val', r.value);
    }, false);
  }
};
