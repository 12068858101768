export default class Sidebar {
  constructor($container, $toggleButton) {
    this.$container = $container;
    this.$toggleButton = $toggleButton;
    this.$offsetTopElement = document.getElementById(this.$container.dataset.offsetTopElement);
    this.$scroll = document.querySelector(this.$container.dataset.scrollElement);
    this.opened = false;
  }

  init() {
    this.$parentContainer = this.$container.closest('.container');

    // background overlay
    const $sidebarOverlay = document.createElement('div');
    $sidebarOverlay.classList.add('gl_sidebarOverlay');
    this.$sidebarOverlay = document.body.appendChild($sidebarOverlay);

    this.siteContainer = document.querySelectorAll('.container');

    document.body.appendChild(this.$container);

    this.$container.querySelector('.gl_cart_summary_sidebar_close').addEventListener('click', () => {
      this.close();
    });

    this.$toggleButton.addEventListener('click', () => {
      if (this.opened) {
        this.close();
      } else {
        this.open();
      }
    });

    this.scrollElement = this.$scroll ? this.$scroll : window;

    this.scrollElement.addEventListener('scroll', () => {
      this.setTopContainer();
    });
  }

  open() {
    this.opened = true;
    this.setTopContainer();
    this.siteContainer.forEach(($container) => {
      $container.classList.add('gl_ContainerSidebarAnimation');
      $container.classList.add('opening');
      setTimeout(() => {
        $container.classList.add('open');
        setTimeout(() => {
          $container.classList.remove('opening');
        }, 300);
      }, 1);
    });
    this.$container.classList.add('opening');
    this.$sidebarOverlay.classList.add('opening');
    setTimeout(() => {
      this.$sidebarOverlay.classList.add('open');
      this.$container.classList.add('open');
      setTimeout(() => {
        this.$sidebarOverlay.classList.remove('opening');
        this.$container.classList.remove('opening');
      }, 300);
    }, 1);
    this.$sidebarOverlay.addEventListener('click', () => this.close());
  }

  close() {
    this.$sidebarOverlay.removeEventListener('click', () => this.close());
    this.siteContainer.forEach(($container) => {
      $container.classList.add('opening');
      setTimeout(() => {
        $container.classList.remove('open');
        setTimeout(() => {
          $container.classList.remove('opening');
          $container.classList.remove('gl_ContainerSidebarAnimation');
        }, 300);
      }, 1);
    });

    this.$container.classList.add('closing');
    this.$sidebarOverlay.classList.add('closing');
    setTimeout(() => {
      this.$sidebarOverlay.classList.remove('open');
      this.$sidebarOverlay.classList.remove('closing');
      this.$container.classList.remove('open');
      this.$container.classList.remove('closing');
    }, 300);
    this.opened = false;
  }

  setTopContainer() {
    if (this.opened && this.$offsetTopElement) {
      const mouseScroll = this.$scroll ? this.scrollElement.scrollTop : window.scrollY;
      const parentContainerOffset = this.$offsetTopElement.offsetTop
            + this.$offsetTopElement.offsetHeight;

      if (mouseScroll < parentContainerOffset) {
        this.$container.style.top = `${parentContainerOffset - mouseScroll}px`;
      } else {
        this.$container.style.top = 0;
      }
    }
  }
}

window.Sidebar = Sidebar;
