export default () => {
  document.querySelectorAll('.gl_breadcrumb').forEach(($breadcrumb) => {
    const $linkActive = $breadcrumb.querySelector('.breadcrumb_list.active');

    if ($linkActive !== null) {
      const $container = $breadcrumb.querySelector('.gl_breadcrumb_wrapper.list');
      const positionLeft = $linkActive.offsetLeft - $container.offsetLeft;

      const offsetRight = positionLeft + $linkActive.clientWidth / 2 - $container.clientWidth / 2;
      $container.scroll(offsetRight, 0);
    }
  });
};
